// @flow

// Necessary Evil
import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import InstantAction from "../Models/Utils/InstantAction";
import {connect} from "react-redux";
// import localStorageProxy from "../System/localStorage";
// import MasterGetter from "../System/MasterGetter";
// import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import LoadingComponent from "../Components/LoadingComponent";
// import ApiCall from "../System/ApiCall";

// import ReactGA from "react-ga";
// import Script from "react-load-script"

// Import component CSS
/**
 * @class AppContainer
 */
class AppContainer extends React.Component {

    /**
     *
     * @type {string[]}
     */
    static publicScenes = ["login", "register", "password-change", "password-recovery"];

    /**
     * PropTypes
     * @type {{children: any}}
     */
    static propTypes = {
        children: PropTypes.any,
    };
    state = {
        loadingData: false,
    };

    /**
     *
     */
    verifyToken = () => {

    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        InstantAction.initiateHistory(this.props.history);
        InstantAction.initiateDispatcher(this.props.dispatch);
        InstantAction.fetchAppData(this);


        if (localStorage.getItem("JWT") !== null) {
            this.verifyToken();
        } else {
            this.state.loadingData = false;
        }
    }

    /**
     * Component will receive props
     * @param nextProps
     * @param nextState
     */
    componentWillReceiveProps(nextProps, nextState) {

        const {location} = this.props;

        if (nextProps.location.pathname !== location.pathname) {
            /**
             * Log Page view on every location change
             */
            console.log("Google Analytics [LOGGED_PAGE_VIEW]: '" + window.location.pathname + window.location.search + "'");
            ReactGA.pageview(window.location.pathname + window.location.search);
            ReactPixel.pageView();
        }
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {

        const {location} = this.props;

        if (this.state.loadingData) {
            return <LoadingComponent/>;
        } else {
            return <div className={"app-wrapper " + location.pathname.split("/")[1]}>
                <div className={"app-theme"}>
                    {/*<ToastContainer*/}
                    {/*position="top-left"*/}
                    {/*autoClose={false}*/}
                    {/*hideProgressBar*/}
                    {/*newestOnTop={true}*/}
                    {/*closeOnClick*/}
                    {/*rtl*/}
                    {/*pauseOnVisibilityChange*/}
                    {/*draggable*/}
                    {/*pauseOnHover*/}
                    {/*toastClassName="master-toast"*/}
                    {/*/>*/}
                    {this.props.children}
                </div>
            </div>;
        }
    }
}

/**
 * This function maps the state to a
 * prop called `state`.
 *
 * In larger apps it is often good
 * to be more selective and only
 * map the part of the state tree
 * that is necessary.
 */
const mapStateToProps = state => (
    {
        app: state.app,
    });

/**
 * Exporting part of the React.Component file
 */
export default withRouter(connect(mapStateToProps)((AppContainer)));