// @flow

import React from "react";
import {Provider as ReduxProvider} from "react-redux";
import {I18nextProvider} from "react-i18next"; // as we build ourself via webpack
import {BrowserRouter, Switch} from "react-router-dom";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
// Good
import {store} from "../Config/store";
import i18n from "../Config/I18next";
import AppContainer from "./AppContainer";
import routes from "../Config/routes";
import InstantAction from "../Models/Utils/InstantAction";
import {RESET_STORE} from "./App.actions";


import "./App.css";
// import MasterGetter from "../Models/Utils/MasterGetter";


/**
 * @class App
 */
export default class App extends React.Component {

    /**
     * Reset Redux Store for App
     */
    static resetStore = () => {
        InstantAction.dispatch({
            type: RESET_STORE,
        });
    };

    /**
     * Is User Logged
     * @return {boolean}
     */
    static isUserLogged = () => {

        // const app = MasterGetter.getApp();
        // Should be string because of UUID4

        // TODO: Delete this
        return true;
        // return (typeof app.loggedUser === "string");

    };

    /**
     * Initiate SEO Tools
     */
    static initiateSEOTools = () => {

        // Initiate Google Analytics
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        ReactGA.pageview(window.location.pathname + window.location.search);

        // Initiate React pixel
        const pixelsConfig = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: true, 		// enable logs
        };

        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL, null, pixelsConfig);
        ReactPixel.pageView(); // For tracking page view
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        // Default Initiate SEO Tools
        App.initiateSEOTools();
    }

    /**
     * Final Render
     * @returns {*}
     */
    render() {
        return <ReduxProvider store={store}>
            <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                    <AppContainer className="app-wrapper">
                        <Switch>
                            {routes}
                        </Switch>
                    </AppContainer>
                </BrowserRouter>
            </I18nextProvider>
        </ReduxProvider>;

    }
}