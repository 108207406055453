import React from "react";
import Loadable from "react-loadable";
import {Route} from "react-router-dom";
import PrivateRoute from "../Components/PrivateRoute";
import LoadingComponent from "../Components/LoadingComponent";

const Async404 = Loadable({loader: () => import("../Scenes/Error404Scene"), loading: LoadingComponent});
const AsyncLogin = Loadable({loader: () => import("../Scenes/PublicScenes/LoginScene"), loading: LoadingComponent});
const AsyncLanding = Loadable({loader: () => import("../Scenes/PublicScenes/LandingScene"), loading: LoadingComponent});

// Private Scenes
const AsyncOverview = Loadable({
    loader: () => import("../Scenes/PrivateScenes/OverviewScene"),
    loading: LoadingComponent
});

/**
 *
 */
const AsyncBatteries = Loadable({
    loader: () => import("../Scenes/PrivateScenes/BatteriesScene"),
    loading: LoadingComponent
});

/**
 *
 */
const AsyncSettings = Loadable({
    loader: () => import("../Scenes/PrivateScenes/SettingsScene"),
    loading: LoadingComponent
});

/**
 *
 */
const AsyncBatteryDetail = Loadable({
    loader: () => import("../Scenes/PrivateScenes/BatteryDetailScene"),
    loading: LoadingComponent
});

/**
 * Public chunks
 */


/**
 * Export of routes
 * @type {*[]}
 */
const routes = [
    <Route key={"login"} exact path={"/login"} component={AsyncLogin}/>,


    <PrivateRoute key={"batteries"} exact path="/batteries" component={AsyncBatteries}/>,
    <PrivateRoute key={"settings"} exact path="/settings" component={AsyncSettings}/>,
    <PrivateRoute key={"battery-detail"} exact path="/battery/:id" component={AsyncBatteryDetail}/>,

    // Defaults
    <PrivateRoute key={""} exact path="/:page?/:secondary?" component={AsyncOverview}/>,
    <PrivateRoute key={""} exact path="/:page?/:secondary?/:entity?/:id?" component={AsyncLanding}/>,


    <Route key="404" component={Async404}/>
];

export default routes;