// @flow

import PropTypes from "prop-types";
import { attr } from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";

export default class BatteryPrototype extends CRUDModel
{
	// /**
	//  * @param {{id: number, name: string, color: string, voltage: number, capacity: number}} data
	//  * @returns {Object}
	//  */
	// static apiToPayload(data: Object)
	// {
	// 	return {
	// 		id: data.id,
	// 		name: data.name,
	// 		color: data.color,
	// 		voltage: Number(data.voltage),
	// 		capacity: Number(data.capacity) || null,
	// 	};
	// }


	// /**
	//  * @returns {{id: number, name: string, color: string, voltage: string, capacity: string}}
	//  */
	// toApi()
	// {
	// 	return {
	// 		id: this.id,
	// 		name: this.name,
	// 		color: this.color,
	// 		voltage: String(this.voltage),
	// 		capacity: String(this.capacity),
	// 	}
	// }
}

BatteryPrototype.modelName = "BatteryPrototype";

BatteryPrototype.fields = {
	id: attr(), // non-relational field for any value; optional but highly recommended
};

BatteryPrototype.propTypes = {
	name: PropTypes.string.isRequired,
};

export const ADD_BATTERY_PROTOTYPE = "ADD_BATTERY_PROTOTYPE";
export const REMOVE_BATTERY_PROTOTYPE = "REMOVE_BATTERY_PROTOTYPE";
export const UPDATE_BATTERY_PROTOTYPE = "UPDATE_BATTERY_PROTOTYPE";