export default {
    Account: "Account",
    BatteryOriginalCapacity: "Original capacity",
    BatteryPrice: "Price",
    BatteryType: "Type",
    Currency: "Currency",
    CurrentCapacity: "Current capacity",
    DefaultCapacity: "Default Capacity",
    DefaultLifespan: "Default Lifespan",
    Description: "Description",
    Electrolyte: "Electrolyte",
    Email: "Email",
    EmailAgain: "Email Again",
    ExpectedLifespan: "Expected lifespan",
    HandlingScore: "Handling score",
    InitialAvgLifespan: "Initial avg. lifespan",
    Name: "Name",
    NewAccountPassword: "New Account Password",
    Password: "Password",
    Price: "Price",
    RepeatEmailAddress: "Repeat Email Address",
    Search: "Search",
    SortBy: "Sort by",
    Temperature: "Temperature",
    Type: "Type",
    Units: "Units",
    When: "When",
    WithBatteryHandling: "With battery handling",
    YourEmailAddress: "Your Email Address",
    YourMessage: "Your message",
    Capacity: "Capacity",
    TotalWarnings: "Total Warnings",
    OrderBy: "Order by",
    Warnings: "Warnings",
    Age: "Age",
};