import {store} from "../../Config/store";
import orm from "../ORM/index";


/**
 * @class MasterGetter
 */
export default class MasterGetter {

    /**
     * Get entities
     * @returns {*}
     */
    static getEntities() {
        return store.getState().entities;
    }

    /**
     * Get Currently logged user
     * @returns {*}
     */
    static getCurrentUser() {

        const state = store.getState();
        const session = orm.session(state.entities).User;

        return session.withId(state.app.loggedUser);


    }

    /**
     * Check if member logged
     * @returns {boolean}
     */
    static isUserLogged() {

        const state = store.getState();
        return (typeof state.app.loggedMember === "string");

    }

    /**
     * Get app
     */
    static getApp() {

        const state = store.getState();
        return state.app;

    }

    /**
     *
     * @param userId
     * @param entities
     * @return {Model}
     */
    static getUser(userId, entities) {

        let session = orm.session(entities);
        return session.User.withId(userId);
    }

}