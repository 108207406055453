export default {
    Overview: "Overview",
    Settings: "Settings",
    Batteries: "Batteries",
    Calculations: "Calculations",
    BatteryTemplates: "Battery Templates",
    SavingsPotentialApprox: "Savings Potential",
    SavedThisYear: "Saved This Year",
    AvgBatteryLifespan: "Avg. Battery Lifespan",
    ProblemsThisYear: "Problems This Year",
    Statistics: "Statistics",
    Events: "Events",
    TechnicalInfo: "Technical Info",
    AnnualCosts: "Annual Costs"
};