// @flow

import { Model, Session } from "redux-orm";

import ValidatingModel from "./ValidatingModel";

/**
 * Model with default reducer which can handle add, update and remove action.
 */
export default class CRUDModel extends ValidatingModel
{
	static reducer(action: Object, klass: Model, session: Session)
	{
		CRUDModel.connect(session);

		const modelName = klass.modelName.replace(/([A-Z])/g, "_$1").replace(/^_/, "").toUpperCase();

		switch (action.type)
		{
			case "ADD_" + modelName:
				if (!klass.idExists(action.payload.id)) {
					klass.create(action.payload);
				} else {
					klass.withId(action.payload.id).update(action.payload);
				}
				break;
			case "UPDATE_" + modelName:
				klass.withId(action.payload.id).update(action.payload);
				break;
			case "REMOVE_" + modelName:
				const entity = klass.withId(action.payload);
				entity.delete();
				break;
			default:
				return undefined;
		}
	}
}
