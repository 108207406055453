// @flow

import PropTypes from "prop-types";
import {attr, fk} from "redux-orm";

import CRUDModel from "../ORM/CRUDModel";


export default class Battery extends CRUDModel {
    // /**
    //  * @param {{id: number, name: string, color: string, voltage: number, capacity: number}} data
    //  * @returns {Object}
    //  */
    // static apiToPayload(data: Object)
    // {
    // 	return {
    // 		id: data.id,
    // 		name: data.name,
    // 		color: data.color,
    // 		voltage: Number(data.voltage),
    // 		capacity: Number(data.capacity) || null,
    // 	};
    // }


    // /**
    //  * @returns {{id: number, name: string, color: string, voltage: string, capacity: string}}
    //  */
    // toApi()
    // {
    // 	return {
    // 		id: this.id,
    // 		name: this.name,
    // 		color: this.color,
    // 		voltage: String(this.voltage),
    // 		capacity: String(this.capacity),
    // 	}
    // }
}

Battery.modelName = "Battery";

Battery.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
    prototype: fk("BatteryPrototype"),
};

Battery.propTypes = {
    name: PropTypes.string.isRequired,
};

// Battery.defaultProps = {
// };
//
// Battery.endpoint = "/group";

export const ADD_BATTERY = "ADD_BATTERY";
export const REMOVE_BATTERY = "REMOVE_BATTERY";
export const UPDATE_BATTERY = "UPDATE_BATTERY";