// @flow

import MasterDispatcher from "./MasterDispatcher";
import {RESET_STORE, setAppLoaded} from "../../App/App.actions";
import {withRouter} from "react-router";

/**
 * Demo Battery Prototypes
 * @type {{}}
 */
const DEMO_BATTERY_PROTOTYPES = [
    {
        id: 1,
        name: "Battery type A",
        default_capacity: 25000,
        default_lifespan: 10,
        initial_avg_lifespan: 2.3,
        price: 520000,

    },
    {
        id: 2,
        name: "Battery type C",
        default_capacity: 50000,
        default_lifespan: 10,
        initial_avg_lifespan: 4.3,
        price: 420000
    },
    {
        id: 3,
        name: "Battery type E",
        default_capacity: 15000,
        default_lifespan: 9,
        initial_avg_lifespan: 1,
        price: 320000
    }
];

/**
 * Demo Batteries
 * @type {{}}
 */
const DEMO_BATTERIES = [
    {
        id: 1,
        name: "WD-7441",
        prototype: 1,
        capacity: 24000,
        power_left: 23000,
        status: "in-use",
        expected_lifespan: 7.4,
        handling_score: 75,
        warnings: 14,
        new_warnings: 1,
        electrolyte: 99.9,
        temperature: 35,
    },
    {
        id: 8,
        name: "WD-7448",
        prototype: 1,
        capacity: 24000,
        power_left: 14000,
        status: "in-use",
        expected_lifespan: 7.4,
        handling_score: 75,
        warnings: 14,
        new_warnings: 0,
        electrolyte: 99.9,
        temperature: 35,
    },
    {
        id: 2,
        name: "WD-7442",
        prototype: 1,
        capacity: 19500,
        power_left: 1500,
        status: "charging",
        expected_lifespan: 1.4,
        handling_score: 32,
        warnings: 14,
        new_warnings: 0,
        electrolyte: 99.9,
        temperature: 35,
    },
    {
        id: 3,
        name: "WD-7443",
        prototype: 1,
        capacity: 19500,
        power_left: 2547,
        status: "standby",
        expected_lifespan: 3.4,
        handling_score: 68,
        warnings: 665,
        new_warnings: 2,
        electrolyte: 99.9,
        temperature: 35,
    }
    ,
    {
        id: 4,
        name: "WD-7444",
        prototype: 1,
        capacity: 4100,
        power_left: 250,
        status: "charging",
        expected_lifespan: 0.4,
        handling_score: 12,
        warnings: 150,
        new_warnings: 3,
        electrolyte: 99.9,
        temperature: 35,
    },
    {
        id: 5,
        name: "WD-7445",
        prototype: 1,
        capacity: 4100,
        power_left: 3200,
        status: "in-use",
        expected_lifespan: 2.4,
        handling_score: 57,
        warnings: 10,
        new_warnings: 3,
        electrolyte: 99.9,
        temperature: 35,
    }
];

/**
 * Demo Events
 * @type {*[]}
 */
const DEMO_EVENTS = [
    {
        id: 6,
        type: "info",
        description: "Disconnected from charging station"
    },
    {
        id: 5,
        type: "info",
        description: "Successfully charged"
    },
    {
        id: 4,
        type: "info",
        description: "Connected to charging station"
    },
    {
        id: 3,
        type: "warning",
        description: "Disconnected from charging station too soon!"
    },
    {
        id: 2,
        type: "info",
        description: "Connected to charging station"
    },
    {
        id: 1,
        type: "info",
        description: "Disconnected from charging station"
    },
];


/**
 * Instant Action
 */
class InstantAction {

    /**
     * Fetch App Data
     */
    static fetchAppData() {

        /**
         * Default DEMO DATA
         * @type {{battery_prototype: {}, battery: {}}}
         */
        const payload = {
            battery_prototype: DEMO_BATTERY_PROTOTYPES,
            battery: DEMO_BATTERIES,
            battery_event: DEMO_EVENTS,
        };


        MasterDispatcher.dispatch(payload);
        InstantAction.dispatch(setAppLoaded(true));

    }

    /**
     * Pure Dispatcher
     * @param data
     */
    static dispatch(data) {
        InstantAction.dispatcher(data);
    }

    /**
     * Init
     * @param history
     */
    static initiateHistory(history) {
        InstantAction.history = history;
    }

    /**
     * Initiate Dispatcher
     * @param dispatcher {func}
     */
    static initiateDispatcher(dispatcher) {
        InstantAction.dispatcher = dispatcher;
    }

    /**
     * Redirect
     * @param path
     */
    static redirect(path) {
        InstantAction.history.push(path);
    }

    /**
     * Reset Store initial state
     */
    static resetStore() {

        InstantAction.dispatch({
            type: RESET_STORE
        });

    }
}


export default InstantAction = withRouter(InstantAction);