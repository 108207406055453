export const SET_APP_LOADED = "@@BMS/SET_APP_LOADED";
export const SET_APP_URL = "@@BMS/SET_APP_URL";
export const SET_APP_MESSAGE = "@@BMS/SET_APP_MESSAGE";
export const SET_LOGGED_USER = "@@BMS/SET_LOGGED_USER";
export const RESET_STORE = "@@BMS/RESET_STORE";
export const SET_APP_LOADING = "@@BMS/SET_APP_LOADING";
export const SET_SHOW_LOGIN = "@@BMS/SET_SHOW_LOGIN";
export const SET_SIGN_UP_SUCCESS = "@@BMS/SET_SIGN_UP_SUCCESS";
export const SET_SEARCH = "@@BMS/SET_SEARCH";


/**
 * Set Show Login
 * @param status
 * @return {{type: string, status: boolean}}
 */
export function setShowLogin(status: boolean) {

    return {type: SET_SHOW_LOGIN, status};

}

/**
 * Set App Loaded
 * @param status
 * @returns {{type: string, status: boolean}}
 */
export function setAppLoaded(status: boolean) {

    return {type: SET_APP_LOADED, status};

}

/**
 * Set App Loading
 * @param status
 * @returns {{type: string, status: boolean}}
 */
export function appLoading(status: boolean) {

    return {type: SET_APP_LOADING, status};

}

/**
 * Set App Url
 * @param url
 * @returns {{type: string, url: *}}
 */
export function setAppUrl(url) {

    return {type: SET_APP_URL, url};
}

/**
 * Set Search
 * @param searchTerm
 * @return {{type: string, searchTerm: *}}
 */
export function setSearch(searchTerm) {

    return {type: SET_SEARCH, searchTerm};

}


/**
 * Set Logged User
 * @param id
 * @returns {{type: string, id: string}}
 */
export function setLoggedUser(id: string) {
    return {
        type: SET_LOGGED_USER,
        id,
    };
}